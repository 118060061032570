<template>
  <Table
    class="customer-profiling-list-table"
    :columns="columns"
    :rows="customerProfiles"
    :loading="isLoading"
    no-data-message="No available customers"
    @row-clicked="openCustomerProfileDetails"
  >
    <template #cell="{ column, row }">
      <component
        :is="getTableCellComponent(column.key)"
        :column-key="column.key"
        :field="column.field"
        :customer-profile="row"
      />
    </template>
  </Table>
</template>

<script>
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Table from '@/components/common/Table';
import CustomerProfilingListTableCellPercent from './CustomerProfilingListTableCellPercent';
import CustomerProfilingListTableCell from './CustomerProfilingListTableCell';
import CustomerProfilingListTableCellCustomerId from './CustomerProfilingListTableCellCustomerId';
import CustomerProfilingListTableCellFlag from './CustomerProfilingListTableCellFlag';
import CustomerProfilingListTableCellStrength from './CustomerProfilingListTableCellStrength';
import CustomerProfilingListTableCellBetSize from './CustomerProfilingListTableCellBetSize';
import CustomerProfilingListTableCellVolume from './CustomerProfilingListTableCellVolume';
import CustomerProfilingListTableCellHold from './CustomerProfilingListTableCellHold';
import CustomerProfilingListTableCellEdge from './CustomerProfilingListTableCellEdge';
import CustomerProfilingListTableCellClient from './CustomerProfilingListTableCellClient';
import CustomerProfilingListTableCellFirstBetPlaced from './CustomerProfilingListTableCellFirstBetPlaced';
import CustomerProfilingListTableCellLastNote from './CustomerProfilingListTableCellLastNote';

const tableCellComponentMap = {
  default: CustomerProfilingListTableCell,
  customerId: CustomerProfilingListTableCellCustomerId,
  flagCell: CustomerProfilingListTableCellFlag,
  strength: CustomerProfilingListTableCellStrength,
  betSize: CustomerProfilingListTableCellBetSize,
  volume: CustomerProfilingListTableCellVolume,
  hold: CustomerProfilingListTableCellHold,
  edge: CustomerProfilingListTableCellEdge,
  maxBetPercentage: CustomerProfilingListTableCellPercent,
  client: CustomerProfilingListTableCellClient,
  firstBetPlaced: CustomerProfilingListTableCellFirstBetPlaced,
  lastNote: CustomerProfilingListTableCellLastNote,
};

export default {
  components: {
    ...tableCellComponentMap,
    Table,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const columns = computed(() => store.getters['betTicker/allCustomersColumns']);
    const isLoading = computed(() => store.getters['betTicker/allCustomersIsLoading']);
    const customerProfiles = computed(() => store.getters['betTicker/allCustomersData']);

    const getTableCellComponent = (columnId) => {
      const foundTableCell = tableCellComponentMap[columnId];
      if (!foundTableCell) return tableCellComponentMap.default;
      return foundTableCell;
    };
    const openCustomerProfileDetails = ({ id: customerId, operatorId: operator }) => {
      router.push({
        name: 'customer-profiling-details',
        params: {
          customerId,
        },
        query: {
          operator,
          return: JSON.stringify(route.query),
        },
      });
    };

    return {
      columns,
      isLoading,
      customerProfiles,
      getTableCellComponent,
      openCustomerProfileDetails,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-list-table {
  min-width: 100%;
  height: 100%;

  .table-row {
    cursor: pointer;

    &:hover {
      background-color: #fafafa;
    }
  }
}

.customer-profiling-list-table-button {
  position: fixed;
  bottom: 30px;
  right: 50px;
}
</style>
